
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { getIllustrationsPath } from "../../../core/helpers/assets";
  import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
  import { ref } from "vue";
  import { reactive } from "vue";
  import { EnumsConstant } from "@/store/enums/constants";
  import { reinitializeComponents } from "@/core/plugins/keenthemes";
  import { getAccountSourcesRequest } from "@/latipay/apis/services/AccountSourcesService";





  export default defineComponent({
    name: "accountSource",
    components: {},
    setup() {
      const store = useStore();


      const name = ref<string | null>(null);
      const type = ref<string | null>(null);
      const country = ref<string | null>(null);
      const region = ref<string | null>(null);
      const sort = ref<string | null>(null);
      const pageNumber = ref(1);
      const pageSize = ref(20);

      const loading = ref(false);
      const countries = EnumsConstant.CountryCode;


      const formInline = reactive({
        type: "",
        region: "",
        country: "",
        name: ""
      });

      const {
        accountSourcesRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        getAccountSources
      } = getAccountSourcesRequest();


      const getData = async () => {
        loading.value = true;

        // await updateQueries();
        await getAccountSources(
          formInline.type,
          formInline.region,
          formInline.country,
          formInline.name,

          sort.value,
          pageNumber.value,
          pageSize.value
        );
        reinitializeComponents();

        loading.value = false;

        console.log(accountSourcesRef);
      };

      const onSubmit = () => {
        console.log("submit!");
      };



      onMounted(async () => {
        // setCurrentPageTitle("Users");
        await getData();
      });

      const updatePageSize = async (val: number) => {
        console.log(`${val} items per page`);
        pageSize.value = val;
        await getData();
      };
      const updatePageNumber = async (val: number) => {
        console.log(`current page: ${val}`);
        pageNumber.value = val;
        await getData();
      };

      return {
        getIllustrationsPath,
        formInline,
        onSubmit,
        countries,

        getData,
        loading,
        updatePageSize,
        updatePageNumber,
        accountSourcesRef,
        totalRef,
        pageNumberRef,
        pageSizeRef,
        name,
        type,
        region,
        country,

      };
    },
    methods: {
      async resetFormFields() {

        (this.$refs["searchForm"] as any).resetFields();

        await this.getData();

      },
      async onSubmitSearchForm() {
        await this.getData();
      }

    }
  });
